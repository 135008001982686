<template>
  <v-container fluid>
    <base-page-title>Update Project {{ part_number }} {{ project_name }}</base-page-title>
    <ProjectTemplate editMode :activeTab="activeTab ? activeTab : localTab.active_tab"/>
  </v-container>
</template>
<script>
export default {
  name: "EditProject",
  props: ["activeTab"],
  data() {
    return {
      localTab: {
        active_tab: null,
      },
    };
  },
  components: {
    ProjectTemplate: () => import("./ProjectTemplate.vue"),
  },
  computed: {
    project() {
      return this.$store.getters["projects/selProject"];
    },
    part_number() {
      return Object.values(this.project?.fixture_design_details)[0].full_part_number
    },
    project_name() {
      return this.project?.project_name;
    },
  },
  mounted() {
    if (this.activeTab) return;
    if (localStorage.getItem("project")) {
      this.localTab = JSON.parse(localStorage.getItem("project"));
      localStorage.removeItem("project");
    }
  },
};
</script>
